<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="2">
                <el-button
                  type="primary"
                  @click="add_category"
                  v-acl:CategoryEdit.enable.show
                >{{
                  $t("Add")
                }}</el-button>
              </b-col>
              <b-col cols="10">
                <div v-if="!isSortableEnabled">
                  <el-button
                    type="primary"
                    @click="enableSort"
                    v-acl:CategoryEdit.enable.show
                  >
                    {{ $t("Sort Order") }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button
                    type="primary"
                    @click="disableSort"
                    v-acl:CategoryEdit.enable.show
                  >
                    {{ $t("Save Change") }}
                  </el-button> &emsp;
                  Please drag and drop the table.
                </div>
              </b-col>
            </b-row>
            <p class="p-3" v-if="categorylist">
              <el-table
                stripe
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="categorylist"
                @row-click="row_clicked_event"
                ref="dragTable"
                row-key="IDn"
              >
                <!-- <el-table-column label="ID" prop="IDn">
                  <template v-slot="{ row }">
                    {{ row.IDn }}
                  </template>
                </el-table-column> -->
                <el-table-column :label="$t('Department')">
                  <template v-slot="{ row }">
                    {{ departmentlist[row.DepartmentIDn].Name1 }}
                  </template>
                </el-table-column>
                <el-table-column label="PLU" prop="PLU"> </el-table-column>
                <el-table-column :label="$t('Name') + '1'" prop="Name1">
                </el-table-column>
                <el-table-column :label="$t('Name') + '2'" prop="Name2">
                </el-table-column>
                <!-- <el-table-column :label="$t('Sort Order')" prop="SortOrder">
                </el-table-column> -->
                <el-table-column :label="$t('Remarks')" prop="Remarks">
                </el-table-column>
              </el-table>
              <!-- <br />
              <el-row>
                <el-col :span="20">
                  <div>
                    <b-pagination
                      v-model="current_page"
                      :total-rows="product_total"
                      :per-page="per_page"
                    ></b-pagination>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div>
                    <el-dropdown @command="handleCommand">
                      <span class="el-dropdown-link">
                        Max Rows per page {{ this.per_page
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="page_option in per_page_option"
                          :key="page_option"
                          :command="page_option"
                          >{{ page_option }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </el-col>
              </el-row> -->
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <p>{{ cur_category.IDn }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Department')">
                      <select
                        class="form-control"
                        v-model="cur_category.DepartmentIDn"
                      >
                        <option></option>
                        <option
                          v-for="(dt, idx) in departmentlist"
                          :key="idx"
                          :value="dt.IDn"
                        >
                          {{ dt.Name1 }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '1'">
                      <b-input
                        placeholder="Name1"
                        class="form-control"
                        v-model="cur_category.Name1"
                        required
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '2'">
                      <b-input
                        placeholder="Name2"
                        class="form-control"
                        v-model="cur_category.Name2"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <base-input :label="$t('Sort Order')">
                      <b-input
                        type="number"
                        min="1"
                        max="999"
                        placeholder="Sort Order"
                        class="form-control"
                        v-model="cur_category.SortOrder"
                        required
                      />
                    </base-input>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Remarks')">
                      <b-input
                        placeholder="Remarks"
                        class="form-control"
                        v-model="cur_category.Remarks"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-file
                      id='image_upload'
                      v-model="image1"
                      :state="Boolean(image1)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @input='image_upload_input'
                    ></b-form-file>
                    <b-popover
                      :disabled='true'
                      ref="image_popover"
                      target='image_upload'
                      placement='topright'
                      :content="$t('Image size is too big. Make it less than 7MB before uploading.')"
                    ></b-popover>
                  </b-col>
                </b-row>
                <b-row v-bind:style="{ paddingTop: '10px', textAlign: 'center', }">
                  <b-col v-if="url || cur_category.imagefile" :span="6">
                    <img 
                      :src="url ? url : apiBaseUrl + cur_category.imagefile"
                      class="rounded picimg"
                      @click="deleteImage"
                    />
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center" v-if="cur_category.IDn">
                    <el-button
                      type="danger"
                      @click="confirmDialog=true"  
                      v-acl:ProductsDelete.enable.show
                    >
                      <span>{{ $t("Delete") }}</span>
                    </el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col>
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                      <el-tab-pane :label="$t('Modifier Group')" name="Modifier">
                        <b-form-checkbox-group v-model="cur_Modifiers" inline="true" >
                          <el-row>
                            <el-col :span="4" v-for="mod in modifierlist" :key="mod.IDn">
                              <b-form-checkbox :value="mod.IDn">
                                <span class="select_text">
                                  {{ mod.Name1 }}-{{ mod.Name2 }}
                                </span>
                              </b-form-checkbox>
                            </el-col>
                          </el-row>
                        </b-form-checkbox-group>
                        <b-row>
                          <b-col class="text-right">
                            <b-form-checkbox v-model="do_all_product">
                              <span class="select_text">
                                {{ $t('Update All Product In This Category') }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <el-button type="primary" size="mini" @click="update_modifier">{{
                              $t("Update")
                            }}</el-button>
                          </b-col>
                        </b-row>
                      </el-tab-pane>
                      <el-tab-pane :label="$t('Tax')" name="Tax">
                        <b-row>
                          <b-col cols="2">
                            <el-select
                              v-model="cur_TaxTypeIDn"
                              size="mini"
                              :placeholder="$t('Select Tax Type')"
                            >
                              <el-option
                                v-for="tt in taxtypelist"
                                :key="tt.IDn"
                                :label="tt.Name"
                                :value="tt.IDn"
                              >
                              </el-option>
                            </el-select>
                          </b-col>
                          <b-col cols="2" class="text-right">
                            <b-form-checkbox v-model="do_all_product">
                              <span class="select_text">
                                {{ $t('Update All Product In This Category') }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="2">
                            <el-button type="primary" size="mini" @click="update_tax_type">{{
                              $t("Update")
                            }}</el-button>
                          </b-col>
                        </b-row>
                      </el-tab-pane>
                      <el-tab-pane :label="$t('Others')" name="Others">
                        <b-row>
                          <b-col cols="2">
                            <el-select
                              v-model="cur_PrinterIDn"
                              size="mini"
                              :placeholder="$t('Select Printer')"
                            >
                              <el-option
                                v-for="pr in printerlist"
                                :key="pr.IDn"
                                :label="pr.Name"
                                :value="pr.IDn"
                              >
                              </el-option>
                            </el-select>
                          </b-col>
                          <b-col cols="2">
                            <el-switch
                              v-model="cur_NoReceipt"
                              :active-text="$t('Not On Receipt')"
                              :active-value="1"
                              :inactive-value="0"
                            >
                            </el-switch>
                          </b-col>
                          <b-col cols="2" class="text-right">
                            <b-form-checkbox v-model="do_all_product">
                              <span class="select_text">
                                {{ $t('Update All Product In This Category') }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col cols="2">
                            <el-button type="primary" size="mini" @click="update_printer">{{
                              $t("Update")
                            }}</el-button>
                          </b-col>
                        </b-row>
                      </el-tab-pane>
                    </el-tabs>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
      <el-dialog
        :title="$t('Please Confirm Delete')"
        :visible.sync="confirmDialog"
        width="30%"
        center>
        <h1 class="text-danger text-center">{{ $t('Deletion cannot be undone after you confirm') }}</h1>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog = false" class="mr-6">{{ $t('Cancel') }}</el-button>
          <el-button type="primary" @click="deleteConfirmed" class="ml-6">{{ $t('Confirm') }}</el-button>
        </span>
      </el-dialog>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Checkbox, Row, Col, Dropdown, Dialog, Tabs, TabPane, Select, Option, Switch } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";
// import ElTableDraggable from '../../../node_modules/el-table-draggable';

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [Dialog.name]: Dialog,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    // ElTableDraggable,
  },
  data() {
    return {
      url: "",
      currpage: "list",
      cur_category: null,
      cur_Modifiers: null,
      do_all_product: false,
      cur_PrinterIDn: null,
      cur_NoReceipt: 0,
      cur_TaxTypeIDn: null,
      cur_opt: null,
      activeName: null,
      new_category: {
        IDn: "",
        ID: "",
        DepartmentIDn: "",
        DepartmentID: "",
        PrinterIDn: "0",
        TaxTypeIDn: "0",
        NoReceipt: 0,
        PLU: "",
        Name1: "",
        Name2: "",
        SortOrder: "",
        BtnColor: "#cad1d7",
        Remarks: "",
        imagefile: "",

        Modifiers: [],
      },
      departmentlist: null,
      categorylist: null,
      modifierlist: null,
      printerlist: null,
      taxtypelist: null,

      btnselectstyle: { backgroundColor: "#cad1d7" },

      image1: null,
      sortable: null,
      isSortableEnabled: false,

      dismessage: "",
      countdown: 0,
      confirmDialog: false,

      token: "",
      user_id: 0,
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    handleClick(tab, event) {
      if (tab.name == 'Modifier') {
        this.cur_Modifiers = JSON.parse(JSON.stringify(this.cur_category.Modifiers));
      } else if (tab.name == 'Others') {
        this.cur_PrinterIDn = this.cur_category.PrinterIDn;
        this.cur_NoReceipt = this.cur_category.NoReceipt;
      } else if (tab.name == 'Tax') {
        this.cur_TaxTypeIDn = this.cur_category.TaxTypeIDn;
      }
    },
    deleteImage() {
      this.cur_category.imagefile = '';
      this.url = '';
    },
    disableSort() {
      this.sortable.option('disabled', true);
      this.isSortableEnabled = false;
      this.order_submit();
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    enableSort() {
      if (this.categorylist.length <= 0) {
        return;
      }
      // var dptIDn = this.categorylist[0].departmentIDn;
      // for (var i = 1; i < this.categorylist.length; i++){
      //   if (dptIDn != this.categorylist[i].departmentIDn) {
      //     alter($t("Only Same Department Can do sorting"))
      //   }
      // }

      this.setSort();
      // this.sortable.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.categorylist);
          const targetRow = this.categorylist.splice(evt.oldIndex, 1)[0];
          this.categorylist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.categorylist);
        },
      });
    },
    async order_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.categorylist.length; i++){
        orderlist.push({IDn:this.categorylist[i].IDn, SortOrder:i+1});
      }
      bodyFormData.append("SortOrderList", JSON.stringify(orderlist));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Category/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.categorylist = rt.data.categorys;
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    add_category() {
      this.currpage = "edit";
      this.url = '';
      this.cur_category = JSON.parse(JSON.stringify(this.new_category));
      this.cur_Modifiers = JSON.parse(JSON.stringify(this.cur_category.Modifiers));
      this.cur_PrinterIDn = this.cur_category.PrinterIDn;
      this.cur_NoReceipt = this.cur_category.NoReceipt;
      this.cur_TaxTypeIDn = this.cur_category.TaxTypeIDn;
      this.btnselectchange();
      this.cur_opt = "add";
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      if (!hasUtilityAuth('CategoryEdit')) return;
      this.currpage = "edit";
      this.url = '';
      this.cur_category = JSON.parse(JSON.stringify(row));
      this.cur_Modifiers = JSON.parse(JSON.stringify(this.cur_category.Modifiers));
      this.cur_PrinterIDn = this.cur_category.PrinterIDn;
      this.cur_NoReceipt = this.cur_category.NoReceipt;
      this.cur_TaxTypeIDn = this.cur_category.TaxTypeIDn;

      this.btnselectchange();
      this.cur_opt = "update";
    },
    btnselectchange() {
      let color = this.cur_category.BtnColor;
      this.btnselectstyle = { backgroundColor: color };
    },
    form_cancel() {
      this.url = '';
      this.currpage = "list";
      // clear sort status
      if(this.isSortableEnabled){
        this.sortable.option('disabled', true);
        this.isSortableEnabled = false;
        // refresh list data
        this.get_category_list();
      }
      this.image1 = null;
    },
    image_upload_input () {
      if (this.image1 && this.image1.size > 7340032) { // 7M=7340032
        this.$refs.image_popover.$emit('open');
        this.image1 = null;
      }
      else {
        this.$refs.image_popover.$emit('close');
        if (this.image1) {
          this.update_url(this.image1);
        }
      }
    },
    async update_url(file) {
      let self = this;
      let reader = new FileReader();
      reader.onload = function() {
        self.url = reader.result;
      };
      reader.readAsDataURL(this.image1);
    },
    update_cur_category() {
      for (var i=0; i <this.categorylist.length; i++) {
        if (this.categorylist[i].IDn == this.cur_category.IDn) {
          this.cur_category = JSON.parse(JSON.stringify(this.categorylist[i]));
        }
      }
    },
    async update_tax_type() {
      if (!this.cur_category || !this.cur_category.IDn) {
        // New category not do this
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("DoProduct", this.do_all_product);
      bodyFormData.append("CategoryIDn", this.cur_category.IDn);
      bodyFormData.append("TaxTypeIDn", this.cur_TaxTypeIDn);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/category/update_tax_type",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.categorylist = rt.data.categorys;
              that.update_cur_category();
              alert(that.$t("Data Updated Successfully"));
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_printer() {
      if (!this.cur_category || !this.cur_category.IDn) {
        // New category not do this
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("DoProduct", this.do_all_product);
      bodyFormData.append("CategoryIDn", this.cur_category.IDn);
      bodyFormData.append("PrinterIDn", this.cur_PrinterIDn);
      bodyFormData.append("NoReceipt", this.cur_NoReceipt);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/category/update_printer",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.categorylist = rt.data.categorys;
              that.update_cur_category();
              alert(that.$t("Data Updated Successfully"));
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_modifier() {
      if (!this.cur_category || !this.cur_category.IDn) {
        // New category not do this
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("DoProduct", this.do_all_product);
      bodyFormData.append("CategoryIDn", this.cur_category.IDn);
      bodyFormData.append("ModifierGroup", JSON.stringify(this.cur_Modifiers));

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/category/update_modifier",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.categorylist = rt.data.categorys;
              that.update_cur_category();
              alert(that.$t("Data Updated Successfully"));
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async modifier_changed(mod) {
      // if (!this.cur_opt_category.IDn) {
      //   // New category not do this
      //   return;
      // }

      // var bodyFormData = new FormData();
      // bodyFormData.append("token", this.token);
      // bodyFormData.append("user_id", this.user_id);
      // if (this.cur_category.Modifiers.includes(mod.IDn)) {
      //   bodyFormData.append("opt", "Add");
      // } else {
      //   bodyFormData.append("opt", "Delete");
      // }
      // bodyFormData.append("CategoryIDn", this.cur_category.IDn);
      // bodyFormData.append("ModifierGroupIDn", mod.IDn);

      // var that = this;

      // axios({
      //   method: "post",
      //   url: "/Api/Web/category/modifier",
      //   data: bodyFormData,
      // })
      //   .then(function (response) {
      //     if (response.status == "200") {
      //       var rt = response.data;
      //       if (rt.status == 0) {
      //         //that.$router.push("/store");
      //         // that.$router.go();
      //       } else if (rt.status == 4) {
      //         alert(that.$t("No Permission"));
      //         that.$router.go(-1);
      //       } else if (rt.message != undefined) {
      //         alert(rt.message);
      //       }
      //     }
      //   })
      //   .catch(function (error) {
      //     // console.log(error);
      //     that.$router.push("/login");
      //   });
    },
    async deleteConfirmed() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", this.cur_category.IDn);
      var that = this;
      this.confirmDialog = false
      
      axios({
        method: "post",
        url: "/Api/Web/category/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 3;
              that.currpage = "list";
              that.get_category_list();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_category) {
        // if (key == 'Modifiers') {
        //   bodyFormData.append(key, JSON.stringify(cur_modifierlist));
        // }
        if (this.cur_category[key]) {
          bodyFormData.append(key, this.cur_category[key]);
        }
      }
      if (!this.cur_category.imagefile) bodyFormData.append('imagefile', '');
      bodyFormData.append("image1", this.image1);

      axios({
        method: "post",
        url: "/Api/Web/category/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.image1 = null;
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_category_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Category",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.departmentlist = rt.data.departments;
              that.categorylist = rt.data.categorys;
              that.modifierlist = rt.data.modifiers;
              that.printerlist = rt.data.printers;
              that.taxtypelist = rt.data.taxtypes;
              that.new_category.PrinterIDn = (that.printerlist[0] == undefined)? "0": that.printerlist[0].IDn;
              that.new_category.TaxTypeIDn = (that.taxtypelist[0] == undefined)? "0": that.taxtypelist[0].IDn;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_category_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.select_text {
  margin-left: -1rem;
}
.handshape{
  cursor: pointer;
}
.picimg {
  max-height: 14rem;
}
</style>
